import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getFirstName } from '../../redux/selectors';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import InitialIcon from './InitialIcon';

const colourMap = {
  'paulhepplestone@profilepensions.co.uk': 'lavender',
  'samanthapackham@profilepensions.co.uk': 'lavender',
  'richardpenney@profilepensions.co.uk': 'lavender',
  'paul.hepplestone@moneyfarm.com': 'lavender',
  'samantha.packham@moneyfarm.com': 'lavender',
  'richard.penney@moneyfarm.com': 'lavender',
  'olivia.craig@moneyfarm.com': 'lavender',
  'josefine.jonsson@moneyfarm.com': 'lavender',
  'ashleigh.ramsbottom@moneyfarm.com': 'lavender',
};

const DEFAULT_COLOUR = 'interfaceFeedbackTipRegular';

const FirstNameIcon = ({
  firstName,
  adviser,
  size,
  wrapperStyles,
}) => {
  const initial = get(firstName, '0') || '';
  const colour = get(colourMap, get(adviser, 'email')) || DEFAULT_COLOUR;
  return (
    <InitialIcon initial={initial} colour={colour} size={size} wrapperStyles={wrapperStyles} />
  );
};

const mapStateToProps = (state) => ({
  firstName: getFirstName(state),
  adviser: getPensionExpert(state),
});

export default connect(mapStateToProps)(FirstNameIcon);
