import get from 'lodash/get';

const adviserEmailMapping = {
  'paul.hepplestone@profilepensions.co.uk': 'paulhepplestone@profilepensions.co.uk',
  'samantha.packham@profilepensions.co.uk': 'samanthapackham@profilepensions.co.uk',
  'richard.penney@profilepensions.co.uk': 'richardpenney@profilepensions.co.uk',
  'mathew.mcdonald@profilepensions.co.uk': 'mathewmcdonald@profilepensions.co.uk',
  'olivia.craig@profilepensions.co.uk': 'oliviacraig@profilepensions.co.uk',
  'josefine.jonsson@profilepensions.co.uk': 'josefinejonsson@profilepensions.co.uk',
  'ashleigh.ramsbottom@profilepensions.co.uk': 'ashleigh.ramsbottom@moneyfarm.com',
  'paul.hepplestone@moneyfarm.com': 'paul.hepplestone@moneyfarm.com',
  'samantha.packham@moneyfarm.com': 'samantha.packham@moneyfarm.com',
  'richard.penney@moneyfarm.com': 'richard.penney@moneyfarm.com',
  'mathew.mcdonald@moneyfarm.com': 'mathew.mcdonald@moneyfarm.com',
  'olivia.craig@moneyfarm.com': 'olivia.craig@moneyfarm.com',
  'josefine.jonsson@moneyfarm.com': 'josefine.jonsson@moneyfarm.com',
  'ashleigh.ramsbottom@moneyfarm.com': 'ashleigh.ramsbottom@moneyfarm.com',
};

export default (m, customerAdviserEmail) => {
  const fromUsername = get(m, 'fromUsername');
  if (!fromUsername || !customerAdviserEmail) return false;
  return adviserEmailMapping[fromUsername] === customerAdviserEmail;
};
